<template>
  <TTView>
    <VRow>
      <VCol>
        TTDataTable
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <div class="tt-text-title-2 tt-light-mono-100--text py-3">
          Data table title (Compact)
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <div>
          <TTDataTable
            :headers="headers"
            :items="items"
            tall
            disable-pagination
            hide-default-footer
          />
        </div>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <div class="tt-text-title-2 tt-light-mono-100--text py-3">
          Data table title (Normal)
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <div>
          <TTDataTable
            :headers="headers"
            :items="items"
            disable-pagination
            hide-default-footer
          >
            <template #item.data3="{item}">
              <div class="w-100 h-100 d-flex justify-space-between align-center">
                <span class="align-center">{{ item.data3 }}</span>
                <VBtn
                  fab
                  text
                  plain
                >
                  <VIcon size="19">
                    fal fa-ellipsis-h
                  </VIcon>
                </VBtn>
              </div>
            </template>
          </TTDataTable>
        </div>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <div class="tt-text-title-2 tt-light-mono-100--text py-3">
          Data table title (Tall)
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <div>
          <TTDataTable
            :headers="headers"
            :items="items"
            tall
            disable-pagination
            hide-default-footer
          >
            <template #item.data3="{item}">
              <div class="w-100 h-100 d-flex justify-space-between align-center">
                <span class="align-center">{{ item.data3 }}</span>
                <VBtn
                  fab
                  text
                  plain
                >
                  <VIcon size="19">
                    fal fa-ellipsis-h
                  </VIcon>
                </VBtn>
              </div>
            </template>
          </TTDataTable>
        </div>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <div class="tt-text-title-2 tt-light-mono-100--text py-3">
          Data table title (Normal) 5+ cols
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <div>
          <TTDataTable
            :headers="[...headers, ...additionalHeaders]"
            :items="[...items, additionalCol,additionalCol]"
            disable-pagination
            hide-default-footer
          />
        </div>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <div class="tt-text-title-2 tt-light-mono-100--text py-3">
          Actions
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <div>
          <TTDataTable
            :headers="headers"
            :items="items"
            extra-tall
            disable-pagination
            hide-default-footer
          >
            <template #item.data3>
              <TTBtn color="primary">
                Внесите факт
              </TTBtn>
            </template>
          </TTDataTable>
        </div>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <div class="tt-text-title-2 tt-light-mono-100--text py-3">
          Pagination
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <div>
          <TTDataTable
            :headers="headers"
            :items="items"
          />
        </div>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <div class="tt-text-title-2 tt-light-mono-100--text py-3">
          Expand
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <div>
          <TTDataTable
            :headers="headers"
            :items="items"
            show-expand
          >
            <template #expanded-item="{ item }">
              <td />

              <td :colspan="headers.length">
                {{ item.name }}
              </td>
            </template>
          </TTDataTable>
        </div>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
const additionalCol = {
  name: 'Name 1',
  data1: '05.12.2019',
  data2: '13.12.2019',
  data3: '15.01.2020',
};

const additionalHeaders = [
  {
    text: 'Data4',
    value: 'data3',
  },
  {
    text: 'Data5',
    value: 'data3',
  },
];

export default {
  name: 'TTable',
  data() {
    return {
      additionalCol,
      additionalHeaders,
      selected: [],
      professions: [
        {
          text: 'Профессия',
          value: 'p1',
        },
        {
          text: 'Профессия1',
          value: 'p2',
        }, {
          text: 'Профессия2',
          value: 'p3',
        }, {
          text: 'Профессия3',
          value: 'p4',
        }, {
          text: 'Профессия4',
          value: 'p5',
        },
      ],
      headers: [
        {
          text: 'NameNameNameNameNameNameNameNameName',
          value: 'name',
          sortable: false,
          width: 150,
        },
        {
          text: 'Data1',
          value: 'data1',
          sortable: false,
        },
        {
          text: 'Data2',
          value: 'data2',
          sortable: false,
        },
        {
          text: 'Data3',
          value: 'data3',
        },
      ],
      items: [
        {
          name: 'Name 1',
          data1: '05.12.2019',
          data2: '13.12.2019',
          data3: '15.01.2020',
        },
        {
          name: 'Name 1',
          data1: '05.12.2019',
          data2: '13.12.2019',
          data3: '15.01.2020',
        },
        {
          name: 'Name 1',
          data1: '05.12.2019',
          data2: '13.12.2019',
          data3: '15.01.2020',
        },
        {
          name: 'Name 1',
          data1: '05.12.2019',
          data2: '13.12.2019',
          data3: '15.01.2020',
        },
        {
          name: 'Name 1',
          data1: '05.12.2019',
          data2: '13.12.2019',
          data3: '15.01.2020',
        },
      ],
    };
  },
  methods: {
    log(v) {
      console.warn(v);
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
